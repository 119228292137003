import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import Footer from "../components/shared/Footer/Footer";
import {getRoutes} from '../components/shared/routes';
import AdditionalInformation
    from "../components/shared/additionalInformation/additionalInformation";
import PageTitle from "../components/shared/PageTitle/PageTitle";
import PricingModel from "../components/sections/PricingModel/PricingModel";
import OfferingSection from "../components/sections/OfferingSection/OfferingSection";
import Hardware from "../components/sections/Hardware/Hardware";
import OfferingSaasPlatform from "../components/sections/OfferingSaasPlatform/OfferingSaasPlatform";
import OfferingMobileApps from "../components/sections/OfferingMobileApps/OfferingMobileApps";


const Offering = () => {
    const routes = getRoutes().Offering;
    const pageTitle = routes.pageTitle;


    return (
        <Layout>
            <SEO title={pageTitle}/>

            <div id={routes.sections.offeringSection.anchor}>
                <PageTitle>{pageTitle}</PageTitle>
                <OfferingSection/>
            </div>

            <PricingModel/>

            <OfferingSaasPlatform/>

            <OfferingMobileApps/>

            <Hardware/>

            <AdditionalInformation typeMail={'sales'}/>

            <Footer/>

        </Layout>
    );
};

export default Offering;
